import {UpPhotos} from "@/Api/UpPhoto";
import Storage from "@/util/Storage"
import {IssueFriendStore} from "@/views/Friend/IssueFriend/Store";
import {issueFriend} from "@/Api/Friend";
import {issueFriendType} from "@/Api/Friend/interfaceType";
import {Toast} from "vant";

export function upPhotos( list:any[] = [] ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    const fromData = new FormData()
    fromData.append("functionName","friend")
    list.forEach(item=>fromData.append("files",item.file, item.file.name))
    return Promise.resolve( UpPhotos({ userId,token },fromData).then(res=>{
        return res.data
    }) )
}


export function IssueFriendCircle() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    let upData:issueFriendType = {
        content:"",
        queryAuth:IssueFriendStore.getIssueAdmin.type
    }

    if ( IssueFriendStore.getIssueMessage.length <= 0 ) {
        Toast("请输入发布内容")
        return Promise.resolve( false )
    }else{
        upData.content = IssueFriendStore.getIssueMessage
    }
    if ( IssueFriendStore.getIssueImgList.length ) {
        let strPath = ""
        IssueFriendStore.getIssueImgList.forEach((a,i)=>{
            if ( i )
                strPath += "," + a;
            else
                strPath = a
        })
        upData.image = strPath
    }
    /** 朋友全定位 */
    let addressData = IssueFriendStore.getIssueAddress
    if ( addressData.state == "ok" ){
        if ( addressData.showSiteStr ){
            upData.address = addressData.showSiteStr
        }else if ( addressData.address ){
            upData.address = addressData.address
        }else {
            upData.address = `${ addressData.city }${ addressData.district }${ addressData.township }`
        }
    }else{
        upData.address = ""
    }


    return Promise.resolve( issueFriend({ userId,token },upData).then(res=>{
        IssueFriendStore.clearIssueFriend()
        Toast.success("发布成功")
        return res.data
    }) )
}
