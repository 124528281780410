

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import IssueFriendStoreType, {
    IssueFriendAddressType,
    IssueFriendAdmin
} from "@/views/Friend/IssueFriend/Store/indexType";

@Module({
    name:"IssueFriend",
    store,
    dynamic: true,
})
export default class IssueFriend extends VuexModule implements IssueFriendStoreType{

    IssueAddress:IssueFriendAddressType = {
        state:"off",
        address:"不显示位置"
    };
    get getIssueAddress(){
        return this.IssueAddress
    }
    @Mutation
    setIssueAddress(data: IssueFriendAddressType): void {
        this.IssueAddress = data
    }

    IssueAdmin: IssueFriendAdmin = {
        value:"全国朋友可见",
        type:0
    };
    get getIssueAdmin(){
        return this.IssueAdmin
    }
    @Mutation
    setIssueAdmin(data: IssueFriendAdmin): void {
        this.IssueAdmin = data
    }

    IssueImgList: string[] = [];
    get getIssueImgList(){
        return this.IssueImgList
    }
    @Mutation
    setIssueImgList(list: any[]): void {
        this.IssueImgList = list
    }

    IssueMessage = "";
    get getIssueMessage(){
        return this.IssueMessage
    }
    @Mutation
    setIssueMessage(val: string): void {
        this.IssueMessage = val
    }

    @Mutation
    clearIssueFriend(): void {
        this.IssueMessage = ""
        this.IssueAdmin = {
            value:"全国朋友可见",
            type:0
        }
        this.IssueImgList = []
        this.IssueAddress = {
            state:"off",
            address:"不显示位置"
        };
    }

}

export const IssueFriendStore = getModule( IssueFriend )
